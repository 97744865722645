import { Model } from '../classes/Model';
import { Arquivo } from '../../classes/Arquivo/Arquivo';

export class Cadastro extends Model {
  constructor() {
    super();
    this.codigo = 0;
    this.arquivos = [];
  }

  importarArquivos(arquivos) {
    arquivos = arquivos.filter(arquivo => arquivo.nomeArq);

    this.arquivos = [].concat(
      arquivos.map(arquivo => {
        let arquivoObjeto = new Arquivo();
        arquivoObjeto.json = arquivo;
        return arquivoObjeto;
      }),
    );
  }

  addArquivo(arquivo) {
    this.arquivos.push(arquivo);
  }

  removerArquivo(arquivo) {
    this.arquivos = this.arquivos.filter(a => a.codigo !== arquivo.codigo);
  }

  mapeamento = {};

  getNomeBackend(nomeFrontend) {
    try {
      if (!nomeFrontend) {
        throw new Error('O nome do frontend não foi fornecido.');
      }

      const nomeBackend = this.mapeamento[nomeFrontend];

      if (!nomeBackend) {
        throw new Error(`Nenhum nome de backend encontrado para "${nomeFrontend}".`);
      }

      return nomeBackend;
    } catch (error) {
      throw error;
    }
  }

  getNomeFrontend(nomeBackend) {
    try {
      if (!nomeBackend) {
        throw new Error('O nome do backend não foi fornecido.');
      }

      const index = Object.values(this.mapeamento).indexOf(nomeBackend);

      if (index === -1) {
        throw new Error(`Nenhum nome de frontend encontrado para "${nomeBackend}".`);
      }

      return Object.keys(this.mapeamento)[index];
    } catch (error) {
      throw error;
    }
  }
  get json() {}

  set json(value) {}
}
