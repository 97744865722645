export default [
  {
    path: '/evento/consulta',
    name: 'consulta_evento',
    component: () => import('@/views/evento/consulta/ConsultaEvento.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/evento/convite/:credenciais(.*)',
    name: 'convite_evento',
    component: () => import('@/views/confirmarPresenca/ConfirmarPresenca'),
  },
  {
    path: '/evento/:id',
    name: 'detalhe_evento',
    component: () => import('@/views/evento/form/FormEvento.vue'),
    meta: {
      requireAuth: true,
    },
  },
];
