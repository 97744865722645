export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login'),
  },
  {
    path: '/esqueceu-senha',
    name: 'esqueceu-senha',
    component: () => import('@/views/esqueceuSenha/EsqueceuSenha.vue'),
  },
  {
    path: '/recuperar-senha/:payload(.*)',
    name: 'recuperar-senha',
    component: () => import('@/views/recuperarSenha/RecuperarSenha.vue'),
  },
  {
    path: '/login/:operacao',
    name: 'login-operacao',
    component: () => import('@/views/login/Login'),
  },
];
