export default [
  {
    path: '/servidor/consulta',
    name: 'consulta_servidor',
    component: () => import('@/views/servidores/consulta/ConsultaServidor'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/servidor',
    name: 'novo_servidor',
    component: () => import('@/views/servidores/form/FormServidor'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/servidor/:id',
    name: 'servidores',
    component: () => import('@/views/servidores/form/FormServidor'),
    meta: {
      requireAuth: true,
    },
  },
];
