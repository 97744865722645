export default [
  {
    path: '/revogacao/:credenciais(.*)',
    name: 'revogacao-email-com-credencial',
    component: () => import('@/views/revogacaoEmail/RevogacaoEmail'),
  },
  {
    path: '/revogacao',
    name: 'revogacao-email',
    component: () => import('@/views/revogacaoEmail/RevogacaoEmail'),
  },
  {
    path: '/validacaoemail/:credenciais(.*)',
    name: 'validacao-email',
    component: () => import('@/views/validacaoEmail/ValidacaoEmail'),
  },
];
