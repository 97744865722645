import { api } from '../../api';

class Controller {
  campoChave = '';
  ordenacao = {
    campo: '',
    sentido: '',
  };

  filtros = [];
  filtroInicial = {};
  filtrosFixo = [];
  filtrosAplicaveis = [];

  titulo = '';

  quantidadeRegistroPorPagina = 50;
  registros = [];
  quantidadeTotalRegistros = 0;
  pagina = 1;

  constructor(rota, titulo = '', classe) {
    this.rota = rota;
    this.titulo = titulo;
    this.classe = classe;
    if (classe) this.classeInstance = new classe();
  }

  validaController() {
    if (Object.keys(this.filtroInicial).length === 0 && this.filtroInicial.constructor === Object) {
      throw new Error(`Defina o filtro inicial em ${this.constructor.name}`);
    }

    if (!this.filtrosAplicaveis.find(filtro => filtro.padrao))
      throw new Error(`Defina o filtro aplicável padrão em ${this.constructor.name}`);
  }

  async gravar(dados) {
    await this.validar(dados);

    if (dados.codigo === 0) return this.incluir(dados);
    return this.alterar(dados);
  }

  async incluir(dados) {
    try {
      const { data } = await api.post(this.rota, { ...dados.json });
      if (this.classe) {
        const instance = new this.classe();
        instance.json = data;
        return instance;
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async buscar(codigo) {
    try {
      const { data } = await api.get(`${this.rota}/${codigo}`);
      if (this.classe) {
        const instance = new this.classe();
        instance.json = data;
        return instance;
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  async alterar(dados) {
    try {
      const { data } = await api.put(`${this.rota}/${dados.codigo}`, dados.json);
      if (this.classe) {
        const instance = new this.classe();
        instance.json = data;
        return instance;
      }
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async excluir(codigo) {
    try {
      return await api.delete(`${this.rota}/${codigo}`);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async validar(dados) {}

  async buscarPermissoes() {
    try {
      const { data } = await api.get(`${this.rota}/permissions`);

      return {
        data,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  }

  addFiltroAplicavel(atributo, nome, tipo, padrao = false) {
    this.filtrosAplicaveis.push({ atributo, nome, tipo, padrao });
  }

  addFiltroFixo(campo, operador, valor = '') {
    this.filtrosFixo.push({ campo, operador, valor });
  }

  setFiltroInicial(campo, operador, valor) {
    this.filtroInicial.campo = campo;
    this.filtroInicial.operador = operador;
    this.filtroInicial.valor = valor;
  }

  async buscarRegistros(filtros, inicializa) {
    if (inicializa) this.inicializar();

    const { data } = await api.get(this.rota, this.getParametros(filtros));
    const { registros, paginacao } = data;
    this.quantidadeTotalRegistros = paginacao.totalRegistros;

    if (registros.length > 0) this.pagina++;
    if (this.classe) {
      return registros.map(registro => {
        const instance = new this.classe();
        instance.json = registro;
        return instance;
      });
    } else return registros;
  }

  async buscarRegistro(codigo) {
    const campoChave = this.classeInstance.mapeamento.codigo;

    const filtros = [{ campo: campoChave, operador: 'IGUAL', valor: codigo }];
    const registros = await this.buscarRegistros(filtros, true);
    if (!registros[0]) throw new Error('Registro não encontrado');
    return registros[0];
  }

  setOrdenacao(campo, sentido) {
    if (!campo || typeof campo != 'string') throw 'Campo da ordenação inválido';
    if (!['ASC', 'DESC'].includes(sentido)) throw 'Sentido da ordenação inválido';

    this.ordenacao.campo = campo;
    this.ordenacao.sentido = sentido;
  }

  getParametros(filtros) {
    const params = {
      filtros: JSON.stringify(filtros.concat(this.filtrosFixo)),
      limite: this.quantidadeRegistroPorPagina,
      pagina: this.pagina,
    };

    // Adiciona ordenacao e sentido somente se estiverem preenchidos
    if (this.ordenacao.campo && this.ordenacao.sentido) {
      params.ordenacao = this.ordenacao.campo;
      params.sentido = this.ordenacao.sentido;
    }

    return { params };
  }

  inicializar() {
    this.pagina = 1;
    this.registros = [];
  }

  // Daqui pra baixa deve ser sobrescrito pelas classes filhas

  getCodigo(registro) {
    throw new Error('Sobrescreva a função getCodigo no controller filho');
  }

  getDescricao(registro) {
    throw new Error('Sobrescreva a função getDescricao no controller filho');
  }

  getDetalhe() {
    return '';
  }

  getAvatar() {
    return '';
  }

  getIcon() {
    return '';
  }

  getQuebra() {
    return '';
  }
}

export { Controller };
