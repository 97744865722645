export default [
  {
    path: '/ticket/:credenciais(.*)',
    name: 'ticket',
    component: () => import('@/views/ticket/form/FormTicket'),
    meta: {
      requireAuth: true,
    },
  },
];
