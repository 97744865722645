import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cidade } from '../Cidade/Cidade';
import { Contato } from '../Contato/Contato';
import { Sistema } from '../Sistema/Sistema';
import { Segmento } from '../Segmento/Segmento';

export class Cliente extends Cadastro {
  constructor(persistente) {
    super();
    this.codigo = 0;
    this.descricao = '';
    this.contatos = [];

    this.sistemas = [];
    this.sistemasExcluidos = [];

    this.segmentos = [];
    this.segmentosExcluidos = [];

    this.cidade = new Cidade();
    this.categoria = {
      codigo: 0,
      descricao: 'INDEFINIDO',
    };
    this.classificacao = {
      codigo: 0,
      descricao: 'INDEFINIDO',
    };
    this.coordenadas = {
      latitude: 0,
      longitude: 0,
    };
    this.pausarAtualizcoesPor = 0;
    this.dataInicioAtividades = '';
    this.dataFimAtivadades = '';
    this.horarioInicioExpediente = '';
    this.horarioTerminoExpediente = '';
    this.atualizacaoPausadaAte = '';
  }

  get json() {
    const segmentos = this.segmentos.map(registro => registro.jsonParaCliente);

    const segmentosExcluidos = this.segmentosExcluidos.map(registro => ({
      ...registro.jsonParaCliente,
      objectState: 'DELETED',
    }));

    return {
      codigoCli: this.codigo,
      razaoCli: this.descricao,
      fantasiaCli: this.nomeFantasia,
      inscricaoCli: this.inscricaoEstadual,
      ativoCli: this.ativo ? 'S' : 'N',
      emailCli: this.email,
      categoriaCli: this.categoria.codigo,
      classificacaoCli: this.classificacao.codigo,
      tokenCli: this.token,
      cnpjCli: this.documento,
      enderecoCli: this.endereco,
      bairroCli: this.bairro,
      cepCli: this.cep,
      ptoreferenciaCli: this.pontoReferencia,
      cidadeCli: this.cidade.codigo,
      estadoCid: this.estado,
      coordenadayCli: this.coordenadas.latitude,
      coordenadoxCli: this.coordenadas.longitude,
      datentradaCli: this.dataInicioAtividades.split('/').reverse().join('-'),
      dataencerramentoCli: this.dataFimAtivadades ? this.dataFimAtivadades.split('/').reverse().join('-') : null,
      expedienteInicioCli: this.horarioInicioExpediente,
      expedienteTerminoCli: this.horarioTerminoExpediente,
      atualizacaoVersaoCli: this.versaoAtualizar,
      atualizacaoHoraCli: this.horarioIdealAtualizacao,
      atualizacaoPausadaCli: this.formataDataAtualizacao(),
      observacaoCli: this.observacoes,
      clientesSegmento: [...segmentos, ...segmentosExcluidos],
    };
  }

  set json(dados) {
    this.codigo = dados.codigoCli;
    this.descricao = dados.razaoCli;
    this.nomeFantasia = dados.fantasiaCli;
    this.documento = dados.cnpjCli ? dados.cnpjCli : '';
    this.inscricaoEstadual = dados.inscricaoCli;
    this.ativo = dados.ativoCli === 'S' ? true : false;
    this.email = dados.emailCli;
    this.token = dados.tokenCli;

    this.endereco = dados.enderecoCli;
    this.bairro = dados.bairroCli;
    this.cep = dados.cepCli ? dados.cepCli : '';

    this.cidade.codigo = Number(dados.cidadeCli);
    this.cidade.descricao = dados.nomeCid;

    this.estado = dados.estadoCid;
    this.pontoReferencia = dados.ptoreferenciaCli;

    this.coordenadas.latitude = Number(dados.coordenadayCli);
    this.coordenadas.longitude = Number(dados.coordenadoxCli);

    this.dataInicioAtividades = dados.datentradaCli ? dados.datentradaCli.split('-').reverse().join('/') : '';

    this.dataFimAtivadades = dados.dataencerramentoCli ? dados.dataencerramentoCli.split('-').reverse().join('/') : '';
    this.horarioInicioExpediente = dados.expedienteInicioCli ? dados.expedienteInicioCli : '';
    this.horarioTerminoExpediente = dados.expedienteTerminoCli ? dados.expedienteTerminoCli : '';

    this.versaoAtualizar = Number(dados.atualizacaoVersaoCli);
    this.horarioIdealAtualizacao = dados.atualizacaoHoraCli ? dados.atualizacaoHoraCli : undefined;
    this.atualizacaoPausadaAte = dados.atualizacaoPausadaCli
      ? new Date(dados.atualizacaoPausadaCli.replace('Z', ''))
      : undefined;
    this.atualizadaoBloqueada = dados.atualizacaobloqueadaCli === 'S' ? true : false;

    this.categoria.codigo = dados.categoriaCli;
    this.categoria.descricao = dados.nomeCat;

    this.classificacao.codigo = dados.classificacaoCli;
    this.classificacao.descricao = dados.nomeCat;

    this.observacoes = dados.observacaoCli;

    if (dados?.clientesContatos) {
      dados.clientesContatos.map(registro => {
        const contato = new Contato();
        contato.json = registro;
        this.contatos.push(contato);
      });
    }

    if (dados?.sisCliente) {
      dados.sisCliente.map(registro => {
        const sistema = new Sistema();
        sistema.json = registro;
        this.sistemas.push(sistema);
      });
    }

    if (dados?.clientesSegmento) {
      dados.clientesSegmento.map(registro => {
        const segmento = new Segmento();
        segmento.json = registro;
        this.segmentos.push(segmento);
      });
    }
  }

  formataDataComponenteAPI(data) {
    const year = data.getFullYear();
    const month = String(data.getMonth() + 1).padStart(2, '0');
    const day = String(data.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00Z`;
  }

  formataDataAtualizacao() {
    if (this.atualizacaoPausadaAte) return this.formataDataComponenteAPI(this.atualizacaoPausadaAte);

    let dataRetorno;

    switch (this.pausarAtualizcoesPor) {
      case 0:
        return undefined;
      case 1:
        dataRetorno = new Date();
        dataRetorno.setDate(dataRetorno.getDate() + 1);
      case 2:
        dataRetorno = new Date();
        dataRetorno.setDate(dataRetorno.getDate() + 3);
        break;
      case 3:
        dataRetorno = new Date();
        dataRetorno.setDate(dataRetorno.getDate() + 7);
        break;
      case 4:
        dataRetorno = new Date();
        dataRetorno.setDate(dataRetorno.getDate() + 14);
        break;
      default:
        return undefined;
    }

    return this.formataDataComponenteAPI(dataRetorno);
  }

  mapeamento = {
    codigo: 'codigoCli',
    descricao: 'razaoCli',
    cnpj: 'cnpjCli',
    inscricaoEstadual: 'inscricaoCli',
    ativo: 'ativoCli',
    email: 'emailCli',
    'contatos[].codigo': 'clientesContatos[].codigoCtc',
    'contatos[].descricao': 'clientesContatos[].descricaoCtc',
    'contatos[].email': 'clientesContatos[].emailCtc',
  };
}
