export default [
  {
    path: '/basedados/consulta',
    name: 'consulta_basedados',
    component: () => import('@/views/bancoDeDados/consulta/ConsultaBaseDados'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/basedados',
    name: 'nova_basedados',
    component: () => import('@/views/bancoDeDados/form/FormBaseDados'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/basedados/:id',
    name: 'basedados',
    component: () => import('@/views/bancoDeDados/form/FormBaseDados'),
    meta: {
      requireAuth: true,
    },
  },
];
