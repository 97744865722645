export default [
  {
    path: '/servico-cliente',
    name: 'novo_servico_cliente',
    component: () => import('@/views/servicoCliente/form/FormServicoCliente'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/servico-cliente/consulta',
    name: 'servico_cliente_consulta',
    component: () => import('@/views/servicoCliente/consulta/ConsultaServicoCliente'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/servico-cliente/:id',
    name: 'detalhes_servico_cliente',
    component: () => import('@/views/servicoCliente/form/FormServicoCliente'),
    meta: {
      requireAuth: true,
    },
  },
];
